import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import "~/styles/fonts.css";
import "~/styles/style.css";
import posed, { PoseGroup } from "react-pose";
import { ContextProviderComponent } from "./context";

const RouteContainer = posed.div({
	entering: {
		x: "100%",
		opacity: 0
	},
	enter: {
		opacity: 1,
		delay: 10,
		x: 0,
		transition: {
			opacity: { ease: "easeOut", duration: 500 },
			x: { ease: "easeIn", duration: 0 }
		}
	},
	exit: {
		opacity: 0,
		x: "-10%",
		delay: 0,
		transition: {
			opacity: { ease: "easeOut", duration: 0 }
		}
	}
});

// const AnimateNav = posed.nav({
// 	visible: { scale: 1 },
// 	hidden: { scale: 0.97 }
// })

export default class Wrapper extends Component {
	render() {
		const { children, location } = this.props;

		return (
			<ContextProviderComponent>
				<Fragment>
					<PoseGroup preEnterPose="entering">
						<RouteContainer key={location.pathname}>
							<main className="flex min-h-screen w-full">{children}</main>
						</RouteContainer>
					</PoseGroup>
				</Fragment>
			</ContextProviderComponent>
		);
	}
}

Wrapper.propTypes = {
	children: PropTypes.node.isRequired,
	location: PropTypes.object.isRequired
};
